.block {
  background: grey;
  width: 100%;
  height: 300px;
}

.App .swiper {
  overflow: visible;
}
.App .swiper-slide {
  justify-content: flex-end;
}

.App .swiper-pagination {
  display: flex;
  bottom: 0;
  justify-content: center;
}

.App .swiper-pagination-bullet {
  width: 59px;
  border-radius: 0;
  height: 2px;
  background: #121212;
}

.slick-dots {
  position: static;

  & ul {
    list-style: none;
    padding: 0 ;
  }

  &.slick-dots__flex {
    & ul {
      padding: 24px 0;
      margin: 0;
      display: flex;
      justify-content: center;

      & li {
        width: 100%;
        max-width: 59px;
        border-radius: 0;
        height: 2px;
        background: #E5E5E5;
        list-style: none;

        &.slick-active {
          background: #121212;
        }
      }
    }
  }

  &.slick-dots__image {
    position: relative;
    margin-top:5px;
    margin-left: -5px;
    margin-right: -5px;
    display:flex !important;
    justify-content: center;
    & li {
      list-style: none;
      display: inline-block;
      width: 128px;
      height: 180px;
      margin: 5px;

      & a {
        opacity: 0.5;
        width: 80%;
        height: auto;
      }

      &.slick-active {
        a {
          opacity: 1;
        }
      }
    }
  }
  &.slick-dots__vertical {
    position: absolute;
    top: 0;
    left: -20vw;
    flex-direction: column;
  }
}


.App .slick-dots li button {
  width: 100%;

  &:before {
    display: none;
  }
}

.App .products-list .slick-slide {
  margin: 0 20px;
}

@media screen and (max-width: 992px) {
  .App .products-list .slick-slide {
    margin: 0 4px;
  }
}


.App .swiper-button-prev,
.App .swiper-button-next {
  width: 48px;
  height: 48px;
  background: #fff;
  border: 1px solid #eee0d8;
  color: #121212;
  border-radius: 50%;
  top: 42%;
}

.App .swiper-button-prev.swiper-button-disabled,
.App .swiper-button-next.swiper-button-disabled {
  opacity: 1;
}

.App .swiper-button-prev {
  left: -24px;
}

.App .swiper-button-next {
  right: -27px;
}

.App .swiper-button-prev::after {
  font-size: 16px;
  font-weight: 900;
  padding-right: 3px;
}

.App .swiper-button-next::after {
  font-size: 16px;
  font-weight: 900;
  padding-left: 5px;
}

a {
  text-decoration: none;
}

.button_plus {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  opacity: 0.85;
  border-radius: 44px;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}


.button_plus:after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  height: 1.5px;
  width: 14px;
  background: #121212;
  top: 50%;
  left: 50%;
  border-radius: 2px;
}

.button_plus:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #121212;
  height: 14px;
  width: 1.5px;
  border-radius: 2px;
}

.button_plus.active:before {
  transition: 0.2s;
  width: 0;
}
