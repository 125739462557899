@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.navbar {
  background: #e9ebfc;
}

.slick-list {
  overflow: hidden;
}
.slick-slider {
  position: relative;
}
.slick-slide {
  width: 128px;
  margin: 0 ;
}
//.slide-small {
//  max-width: 128px;
//}
//
.slick-track {
  display: flex;
  justify-content: center;
}

.slide-big {
  max-width: 430px;
  margin: 0 auto;
  display: block !important;
}
.slide-img {
  max-width: 100%;
  max-height: 100vh;
  margin: 0 auto;
}
.slick-next {
  right: 10px;
}
.slick-prev {
  left: -15px;
}
.slick-next,
.slick-prev {
  position: absolute;
  z-index: 1;
}
.slick-next svg,
.slick-prev svg {
  width: 48px;
  height: 48px;
}
.slick-next::before,
.slick-prev::before {
  content: none;
}

.video {
  width: 100%;
  object-fit: cover;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/font/lato-bold.eot');
  src: local('Lato Bold'), local('Lato-Bold'),
    url('./assets/font/lato-bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/lato-bold.woff2') format('woff2'), url('./assets/font/lato-bold.woff') format('woff'),
    url('./assets/font/lato-bold.ttf') format('truetype'), url('./assets/font/lato-bold.svg#lato') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/font/lato-bolditalic.eot');
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
    url('./assets/font/lato-bolditalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/lato-bolditalic.woff2') format('woff2'),
    url('./assets/font/lato-bolditalic.woff') format('woff'),
    url('./assets/font/lato-bolditalic.ttf') format('truetype'),
    url('./assets/font/lato-bolditalic.svg#lato') format('svg');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/font/lato-regular.eot');
  src: local('Lato Regular'), local('Lato-Regular'),
    url('./assets/font/lato-regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/lato-regular.woff2') format('woff2'),
    url('./assets/font/lato-regular.woff') format('woff'),
    url('./assets/font/lato-regular.ttf') format('truetype'),
    url('./assets/font/lato-regular.svg#lato') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/font/lato-italic.eot');
  src: local('Lato Italic'), local('Lato-Italic'),
    url('./assets/font/lato-italic.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/lato-italic.woff2') format('woff2'), url('./assets/font/lato-italic.woff') format('woff'),
    url('./assets/font/lato-italic.ttf') format('truetype'),
    url('./assets/font/lato-italic.svg#lato') format('svg');
  font-weight: 400;
  font-style: italic;
}
